import React from 'react';
import classnames from 'classnames';

const TrGray = ({ colSpan, text, hint, modifier, forceMainColSpanExtend }) => {
  let mainColSpan;
  let tailColSpan;
  if (forceMainColSpanExtend) {
    mainColSpan = 1;
    tailColSpan = colSpan;
  } else {
    mainColSpan = colSpan > 1 ? 2 : 1;
    tailColSpan = colSpan > 1 ? (colSpan - 1) : 0;
  }
  return (
    <tr>
      <th
        colSpan={ mainColSpan }
        className={ classnames('th-section -sticky-left', modifier) }
      >
        <b>{text}</b>
        { !!hint && <><br />{ hint }</> }
      </th>
      {
        !!tailColSpan &&
        <th colSpan={ tailColSpan } className={ classnames('th-section', modifier) } />
      }
    </tr>
  );
};

export default TrGray;
