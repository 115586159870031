import React, { useState, useRef, useEffect } from 'react';

const InputAllowed = ({ replaceReg, value, onChange }) => {
  const [ cursorData, setCursorData ] = useState(null);
  const inputRef = useRef(null);
  const handleChange = (event) => {
    const changedValue = event.target.value;
    let newStart = event.target.selectionStart;
    const changedValueWithReplacement = changedValue.replaceAll(replaceReg, '');
    if (changedValue !== changedValueWithReplacement) {
      newStart -= 1;
    }
    setCursorData({ start: newStart });
    onChange(changedValueWithReplacement);
  };
  useEffect(() => {
    if (cursorData && inputRef.current && cursorData.start >= 0) {
      inputRef.current.setSelectionRange(cursorData.start, cursorData.start);
    }
  }, [ cursorData ]);
  return (
    <input
      ref={ inputRef }
      className="form-field -block"
      maxLength="250"
      size="250"
      type="text"
      value={ value }
      onChange={ handleChange }
    />
  );
};

export default InputAllowed;
