import { fakeData } from '../../common/fakeData';

// language can be "us"/"uk"/"de"
const paymentButtonsList = (countryCode) => {
  const allPaymentLists = fakeData.allPaymentLists;
  return (
    [
      allPaymentLists.applePay,
      { ...allPaymentLists.googlePay, ...{ width: 'half' } },
      { ...allPaymentLists.paypal, ...{ width: 'half' } },
      allPaymentLists.klarna
    ]
  );
};

export { paymentButtonsList };
