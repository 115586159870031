import React, { useContext } from 'react';
import { GlobalContext } from "../../common/context";
import Products from "../../common/screens/products";
import Cart from "../flow2/cart";
import Payment from "../flow2/payment";

const Flow2App = () => {
  const { step, initialLoading } = useContext(GlobalContext);
  return (
    <>
      { step === 'products' && <Products initialLoading={ initialLoading } /> }
      { step === 'cart' && <Cart /> }
      { step === 'payment' && <Payment /> }
    </>
  );
};

export default Flow2App;
