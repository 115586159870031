import React, { useContext } from 'react';
import { TrData } from '../trData';
import TrGray from '../../../admin/projects/components/comparison/trGray';
import { GlobalContext } from '../../contexts';
import StatTestingNote from '../statTestingNote';
import CommonTableHeader from './commonTableHeader';

const Scorecard = () => {
  const { dataAsArray, mainData, noTotalColumn } = useContext(GlobalContext);
  const colSpan = noTotalColumn ? dataAsArray.length : (dataAsArray.length + 1);
  const forceMainColSpanExtend = !!noTotalColumn;
  return (
    <>
      <div className="comparisons-table_wrapper -side-by-side">
        <table className="table comparisons-table">
          <tbody>
            <CommonTableHeader ccScore />
            <TrGray colSpan={ colSpan } text="KPIs" forceMainColSpanExtend={ forceMainColSpanExtend } />
            <TrData
              data={ dataAsArray }
              text="Ease of Experience"
              hint="(&quot;Little&quot; or &quot;No&quot; effort)"
              metricFunc={ (dataItem) => (dataItem.kpi.vcc_effort) }
            />
            <TrData
              data={ dataAsArray }
              text={ mainData.branded_texts.vcc_customer_first }
              hint="(Agree &quot;Completely&quot; or &quot;Somewhat&quot;)"
              metricFunc={ (dataItem) => (dataItem.kpi.vcc_customer_first) }
            />
            <TrData
              data={ dataAsArray }
              text="Created with My Needs in Mind"
              hint="(Agree &quot;Completely&quot; or &quot;Somewhat&quot;)"
              metricFunc={ (dataItem) => (dataItem.kpi.vcc_needs) }
            />
            <TrData
              className="-dashed"
              data={ dataAsArray }
              text="CX 'North Star'"
              hint="(Feel &quot;Delighted&quot;)"
              metricFunc={ (dataItem) => (dataItem.kpi.vcc_delight) }
            />
          </tbody>
        </table>
      </div>
      <StatTestingNote />
    </>
  );
};

export default Scorecard;
