import React, { useContext } from 'react';
import { GlobalContext } from '../common/context';

const MiddleIntro = ({ nextIndex, total, next }) => {
  const { translate, countryCode } = useContext(GlobalContext);
  let keyText;
  let keyButtonText;

  if (nextIndex < total - 1) {
    keyText = 'middleIntroFirst';
    keyButtonText = 'middleIntroFirstButton';
  } else {
    keyText = 'middleIntroLast';
    keyButtonText = 'middleIntroLastButton';
  }

  const handleClick = () => {
    next(true);
  };

  return (
    <div className="finish">
      <div className="finish_content">
        <h2 className="finish_title">{ translate(keyText, countryCode) }</h2>
        <div className="finish_button">
          <button className="button -sm -red -full-width" onClick={ handleClick }>
            { translate(keyButtonText, countryCode) }
          </button>
        </div>
      </div>
    </div>
  );
};

export default MiddleIntro;
