import { fakeData } from '../../common/fakeData';

// language can be "us"/"uk"/"de"
const paymentButtonsList = (countryCode) => {
  const allPaymentLists = fakeData.allPaymentLists;
  switch (countryCode) {
    case 'de':
      return (
        [
          allPaymentLists.creditCard,
          allPaymentLists.googlePay,
          allPaymentLists.paypal,
          allPaymentLists.applePay,
          allPaymentLists.sofort,
          allPaymentLists.klarna
        ]
      );
    case 'uk':
      return (
        [
          allPaymentLists.creditCard,
          allPaymentLists.googlePay,
          allPaymentLists.paypal,
          allPaymentLists.applePay,
          allPaymentLists.directPay,
          allPaymentLists.klarna
        ]
      );
    default:
      return (
        [
          allPaymentLists.creditCard,
          allPaymentLists.applePay,
          allPaymentLists.paypal,
          allPaymentLists.googlePay,
          allPaymentLists.amazonPay,
          allPaymentLists.shopPay
        ]
      );
  }
};

export { paymentButtonsList };
