import React, { useContext } from 'react';
import { TrData } from '../trData';
import TrGray from '../../../admin/projects/components/comparison/trGray';
import { GlobalContext } from '../../contexts';
import StatTestingNote from '../statTestingNote';
import CommonTableHeader from './commonTableHeader';

const Unhappy = () => {
  const { dataAsArray, mainData, noTotalColumn } = useContext(GlobalContext);
  const colSpan = noTotalColumn ? dataAsArray.length : (dataAsArray.length + 1);
  const forceMainColSpanExtend = !!noTotalColumn;
  return (
    <>
      <div className="comparisons-table_wrapper -side-by-side">
        <table className="table comparisons-table">
          <tbody>
            <CommonTableHeader />
            <TrGray
              forceMainColSpanExtend={ forceMainColSpanExtend }
              colSpan={ colSpan }
              text="Post-Exposure to Unhappy Path Scenario"
            />
            <TrData
              data={ dataAsArray }
              text="Ease of Experience"
              hint='("Little" or "No" effort)'
              metricFunc={ (dataItem) => (dataItem.unhappy_path.vcc_deviation_effort) }
              format="plus"
            />
            <TrData
              data={ dataAsArray }
              text={ mainData.branded_texts.vcc_customer_first }
              hint='(Agree "Completely" or "Somewhat")'
              metricFunc={ (dataItem) => (dataItem.unhappy_path.vcc_deviation_values) }
              format="plus"
            />
            <TrGray
              forceMainColSpanExtend={ forceMainColSpanExtend }
              colSpan={ colSpan }
              text="Customer Case Scores"
            />
            <TrData
              data={ dataAsArray }
              text="Happy Path Scenario"
              metricFunc={ (dataItem) => (dataItem.unhappy_path.happy_path) }
              format="number"
            />
            <TrData
              data={ dataAsArray }
              text="Unhappy Path Scenario"
              metricFunc={ (dataItem) => (dataItem.unhappy_path.unhappy_path) }
              format="number"
            />
          </tbody>
        </table>
      </div>
      <StatTestingNote />
    </>
  );
};

export default Unhappy;
