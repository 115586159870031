import React from "react";

const StatTestingNote = () => {
  return (
    <div className="comparisons_legend-letters-vs-each">
      <div className="note-item">
        <b>Uppercase letters</b> indicate a significantly higher percentage than the referenced column(s) at the 95%
        confidence level.
      </div>
      <div className="note-item">
        <b>Lowercase letters</b> indicate a significantly higher percentage than the referenced column(s) at the 90%
        confidence level.
      </div>
    </div>
  );
};

export default StatTestingNote;
