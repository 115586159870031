import React, { useContext } from 'react';
import { GlobalContext } from '../common/context';

const Finish = ({ onReset }) => {
  const { translate, countryCode } = useContext(GlobalContext);
  return (
    <div className="finish">
      <div className="finish_content">
        <h2 className="finish_title">{ translate("finishTitle", countryCode) }</h2>
        <div className="finish_button">
          <button className="button -sm -red -full-width" onClick={ onReset }>
            { translate("finishButton", countryCode) }
          </button>
        </div>
      </div>
    </div>
  );
};

export  default Finish;
