import React, { Fragment, useContext } from 'react';
import CreatableSelect from 'react-select/creatable';
import { components } from 'react-select';
import { map, compact, find, includes, flatten, remove } from 'lodash';
import classnames from 'classnames';
import { vgSelectStyles } from '../../admin/projects/components/helpers';
import { GlobalContext, FilterItemContext } from '../contexts';
import { filtersFromStringToStructure, defaultHighLevelLogic } from '../helpers';

const generateMultiList = (structureItem, filtersList) => compact(
  map(
    structureItem.values,
    (item, index) => (
      includes([ 'filter', 'logic' ], item.kind) ?
        {
          value: (item.kind === 'filter' ? item.value : `${item.value}-${index}`),
          label: (
            item.kind === 'filter' ?
              find(filtersList, (el) => el.value === item.value)?.label :
              item.value
          )
        } :
        null
    )
  )
);

const MultiValueRemove = (props) => {
  return (
    <components.MultiValueRemove { ...props }>
      <button className="button-multi-value-remove" />
    </components.MultiValueRemove>
  );
};

const ClearIndicator = (props) => {
  const { filtersList } = useContext(GlobalContext);
  const { structureItem, updateState } = useContext(FilterItemContext);
  const handleLogicClick = (event, itemName) => {
    event.preventDefault();
    event.stopPropagation();
    structureItem.defaultLogic = itemName;
    updateState(structureItem, generateMultiList(structureItem, filtersList));
  };

  const handleClearClick = (event) => {
    event.preventDefault();
    event.stopPropagation();
    structureItem.values = [];
    updateState(structureItem, structureItem.values);
  };

  return (
    <div className="custom-indicators">
      <div className="logic-list">
        {
          map(structureItem.logicList, (el) => (
            <button
              key={ `logic-${el}` }
              className={ classnames("logic-button", { '-selected': structureItem.defaultLogic === el }) }
              onClick={ (event) => { handleLogicClick(event, el); } }
              onMouseDown={ (event) => { handleLogicClick(event, el); } }
              onTouchEnd={ (event) => { handleLogicClick(event, el); } }
            >
              { el }
            </button>
          ))
        }
      </div>

      <button
        className="clear-button"
        onClick={ handleClearClick }
        onMouseDown={ handleClearClick }
        onTouchEnd={ handleClearClick }
      />
    </div>
  );
};

const FiltersEditor = ({ structure, onChange }) => {
  const { filtersList, loading } = useContext(GlobalContext);
  const handleOnChangeTags = (structureItem, newValues) => {
    const noLogicNewValues = compact(
      map(newValues, (item) => (includes(structureItem.logicList, item.label) ? null : item))
    );
    structureItem.values = flatten(
      map(
        noLogicNewValues,
        (item, itemIndex) => {
          const newItems = [ { kind: 'filter', value: item.value } ];
          if (itemIndex) {
            newItems.unshift({ kind: 'logic', value: structureItem.defaultLogic });
          }
          return newItems;
        }
      )
    );
    onChange();
  };
  const handleAddFilterGroup = () => {
    structure.push({ kind: 'logic', value: defaultHighLevelLogic });
    structure.push(filtersFromStringToStructure('')[0]);
    onChange();
  };
  const handleRemoveFilterGroup = (removeIndex) => {
    remove(
      structure,
      (el) => el === structure[removeIndex] || el === structure[removeIndex + 1]
    );
    onChange();
  };

  return (
    <>
      {
        map(structure, (structureItem, index) => (
          <Fragment
            key={
              `structure-${index}-${map(structureItem.values, (item) => item.value).join('-')}`
            }
          >
            <FilterItemContext.Provider value={ { structureItem, updateState: handleOnChangeTags } }>
              {
                structureItem.kind === 'complex' ? (
                  <CreatableSelect
                    defaultValue={ generateMultiList(structureItem, filtersList) }
                    className="react-select-container"
                    classNamePrefix="react-select"
                    options={ filtersList }
                    onChange={ (newValue) => { handleOnChangeTags(structureItem, newValue); } }
                    isSearchable
                    isMulti
                    autosize={ false }
                    maxMenuHeight={ 150 }
                    styles={ vgSelectStyles }
                    isDisabled={ loading }
                    placeholder="Select Filter..."
                    components={ { ClearIndicator, MultiValueRemove } }
                    isValidNewOption={ () => (false) }
                  />
                ) : (
                  <div className="add-logic-separator">
                    <span className="logic-separator-title">{ structureItem.value }</span>
                    <button
                      className="clear-button"
                      onClick={ () => handleRemoveFilterGroup(index) }
                    />
                  </div>
                )
              }
            </FilterItemContext.Provider>
          </Fragment>
        ))
      }
      <div className="add-logic-separator">
        <button className="button-add-separator" onClick={ handleAddFilterGroup }>
          ADD FILTER GROUP
        </button>
      </div>
    </>
  );
};

export default FiltersEditor;
