import React, { useContext } from 'react';
import { TrData } from '../trData';
import TrGray from '../../../admin/projects/components/comparison/trGray';
import { GlobalContext } from '../../contexts';
import StatTestingNote from '../statTestingNote';
import CommonTableHeader from './commonTableHeader';

const Diagnostics = () => {
  const { dataAsArray, mainData, noTotalColumn } = useContext(GlobalContext);
  const colSpan = noTotalColumn ? dataAsArray.length : (dataAsArray.length + 1);
  const forceMainColSpanExtend = !!noTotalColumn;
  return (
    <>
      <div className="comparisons-table_wrapper -side-by-side">
        <table className="table comparisons-table">
          <tbody>
            <CommonTableHeader />
            <TrGray
              forceMainColSpanExtend={ forceMainColSpanExtend }
              colSpan={ colSpan }
              text="&apos;Big Idea&apos; Diagnostics"
              modifier="-black"
            />
            <TrGray
              forceMainColSpanExtend={ forceMainColSpanExtend }
              colSpan={ colSpan }
              text={
                <div className="category-title">
                  <span>Category:</span>
                  <span>{ mainData.category }</span>
                </div>
              }
            />
            <TrData
              data={ dataAsArray }
              text="Category Need"
              hint='("Absolutely" or "Very much" needed)'
              metricFunc={ (dataItem) => (dataItem.diagnostics.vcc_need) }
            />
            <TrData
              data={ dataAsArray }
              text="Satisfaction with Category Solutions"
              hint='("Extremely" or "Very" satisfied)'
              metricFunc={ (dataItem) => (dataItem.diagnostics.vcc_satisfaction) }
            />
            <TrGray
              forceMainColSpanExtend={ forceMainColSpanExtend }
              colSpan={ colSpan }
              text={ mainData.branded_texts.solution }
            />
            <TrData
              data={ dataAsArray }
              text="Appeal"
              hint='("Extremely" or "Very" appealing)'
              metricFunc={ (dataItem) => (dataItem.diagnostics.vcc_appeal) }
            />
            <TrData
              data={ dataAsArray }
              text="Difference"
              hint='(Agree "Completely" or "Somewhat")'
              metricFunc={ (dataItem) => (dataItem.diagnostics.vcc_difference) }
            />
            <TrData
              data={ dataAsArray }
              text="Experience is Worthwhile"
              hint='(Agree "Completely" or "Somewhat")'
              metricFunc={ (dataItem) => (dataItem.diagnostics.vcc_worthwhile) }
            />
            <TrGray
              forceMainColSpanExtend={ forceMainColSpanExtend }
              colSpan={ colSpan }
              text="Experience Ease / Effort"
              hint="(Full Scale)"
            />
            <TrData
              data={ dataAsArray }
              text="No Effort / A little effort"
              hint="(Ease KPI)"
              metricFunc={ (dataItem) => (dataItem.diagnostics.vcc_effort.code45) }
            />
            <TrData
              data={ dataAsArray }
              text="Some Effort"
              metricFunc={ (dataItem) => (dataItem.diagnostics.vcc_effort.code3) }
            />
            <TrData
              data={ dataAsArray }
              text="Moderate Effort"
              metricFunc={ (dataItem) => (dataItem.diagnostics.vcc_effort.code2) }
            />
            <TrData
              data={ dataAsArray }
              text="A lot of effort"
              metricFunc={ (dataItem) => (dataItem.diagnostics.vcc_effort.code1) }
            />
            <TrGray
              forceMainColSpanExtend={ forceMainColSpanExtend }
              colSpan={ colSpan }
              text="Additional Diagnostics"
            />
            <TrData
              data={ dataAsArray }
              text="Simple & Intuitive"
              hint='(Agree "Completely" or "Somewhat")'
              metricFunc={ (dataItem) => (dataItem.diagnostics.vcc_simple) }
            />
            <TrData
              data={ dataAsArray }
              text="Honest & No Surprises"
              hint='(Agree "Completely" or "Somewhat")'
              metricFunc={ (dataItem) => (dataItem.diagnostics.vcc_honest) }
            />
            <TrData
              data={ dataAsArray }
              text="Clarity"
              hint="(“Very” or “Somewhat” clear and understandable)"
              metricFunc={ (dataItem) => (dataItem.diagnostics.vcc_process_clarity) }
            />
            {
              !mainData.not_branded &&
              <>
                <TrGray
                  forceMainColSpanExtend={ forceMainColSpanExtend }
                  colSpan={ colSpan }
                  text="Experience Impact"
                />
                <TrData
                  data={ dataAsArray }
                  text={ mainData.branded_texts.vcc_opinion_code3 }
                  hint="(“Very” or “Somewhat” clear and understandable)"
                  metricFunc={ (dataItem) => (dataItem.diagnostics.vcc_opinion.code3) }
                />
                <TrData
                  data={ dataAsArray }
                  text="No Impact"
                  metricFunc={ (dataItem) => (dataItem.diagnostics.vcc_opinion.code2) }
                />
                <TrData
                  data={ dataAsArray }
                  text={ mainData.branded_texts.vcc_opinion_code1 }
                  metricFunc={ (dataItem) => (dataItem.diagnostics.vcc_opinion.code1) }
                />
              </>
            }
          </tbody>
        </table>
      </div>
      <StatTestingNote />
    </>
  );
};

export default Diagnostics;
