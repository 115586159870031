import React, { useContext } from 'react';
import Layout from "../../common/components/layout";
import Total from "../../common/components/total";
import CartList from "../../common/components/cartList";
import { GlobalContext } from "../../common/context";
import BaseCartWrapper from '../../common/components/baseCartWrapper';

const Cart = () => {
  const { goToPayment, cart, translate, countryCode } = useContext(GlobalContext);

  return (
    <BaseCartWrapper>
      <Layout>
        <div className="layout_grid">
          <div className="layout_grid-item -main">
            <div className="cart">
              <h3 className="cart_title -show-mobile">
                { translate("cartSummaryMyItems", countryCode) }
              </h3>
              <CartList />
            </div>
          </div>
          <div className="layout_grid-item -sidebar">
            <div className="plate">
              <h3 className="plate_title">{ translate("cartSummaryTitle", countryCode) }</h3>
              <Total />
              <div className="plate_button">
                <button
                  className="button -red -sm -full-width"
                  onClick={ goToPayment }
                  disabled={ !cart.length }
                >
                  { translate("cartSummaryShippingProceedToCheckout", countryCode) }
                </button>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </BaseCartWrapper>
  );
};

export default Cart;


