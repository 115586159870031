import React, { useEffect, useContext } from 'react';
import { GlobalContext } from '../contexts';
import { generateParametersAsString } from '../helpers';

const UpdateAddressLine = ({ children }) => {
  const { columnsForData, selectedTab, selectedBsg } = useContext(GlobalContext);

  useEffect(() => {
    const parametersString = generateParametersAsString(
      { columns: columnsForData, selectedTab, selectedBsg }
    );
    window.history.replaceState(
      null, "",
      `${location.pathname}?${parametersString}`
    );
  }, [ columnsForData, selectedTab, selectedBsg ]);

  return (children);
};

export default UpdateAddressLine;
