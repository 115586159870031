import classnames from "classnames";
import React, { useContext } from "react";
import { map } from 'lodash';
import Radio from "../../common/components/radio";
import { GlobalContext } from "../../common/context";
import PaymentButton from '../../common/components/paymentButton';

const PaymentRadio = ({
  payment, paymentMethod, updatePaymentMethod, choosePayment
}) => {
  const { countryCode, cell } = useContext(GlobalContext);
  const optionIcon = payment[`optionIcon-cell${cell}`] || payment.optionIcon;
  return (
    <div className={ classnames("payment-radio", `cell-${cell}`) }>
      <Radio name="payment-method" item={ payment } radioChange={ updatePaymentMethod }>
        <div className="main-payment-container">
          {
            !!optionIcon &&
            <div className={ classnames("radio-icon-wrapper", payment.id, `cell-${cell}`) }>
              <svg className={ classnames("payment-radio-icon", payment.id, `cell-${cell}`) }>
                <use xlinkHref={ `#svg_${optionIcon}` } />
              </svg>
            </div>
          }
          {
            payment.radioText &&
            <span className="payment-radio-button_title">{ payment.title(countryCode) }</span>
          }
        </div>
        <div className="available-icons">
          {
            !!payment.optionIcons?.length &&
            <div className="radio-options-wrapper">
              {
                map(payment.optionIcons, (el, iconIndex) => (
                  <div  key={ `opt-${iconIndex}` } className="radio-options-option-wrapper">
                    <svg className={ classnames("payment-radio-options-icon", payment.id) }>
                      <use xlinkHref={ `#svg_${el}` } />
                    </svg>
                  </div>
                ))
              }
            </div>
          }
        </div>
      </Radio>
      {
        paymentMethod === payment.id &&
        <PaymentButton
          payWith
          payment={ payment }
          onClick={ () => choosePayment(payment) }
        />
      }
    </div>
  );
};

export default PaymentRadio;
