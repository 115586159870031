import React, { useEffect, useState, useRef } from 'react';
import { each, shuffle } from 'lodash';
import Flow1App from "./flows/flow1";
import Flow2App from "./flows/flow2";
import Flow3App from "./flows/flow3";
import Finish from "./flows/finish";
import BaseFlowWrapper from './common/components/baseFlowWrapper';
import { initialData, fieldNameByFlowIndex, sendData } from './common/statistics';
import { fakeData } from './common/fakeData';
import MiddleIntro from './flows/middleIntro';

const PaypalCheckoutApp = ({ customFlow, countryCode, cell, customOrder }) => {
  const [ flowList, setFlowList ] = useState([]);
  const [ flowIndex, setFlowIndex ] = useState(0);
  const [ conceptName, setConceptName ] = useState("");

  const respondentsDataStore = useRef(initialData());

  const mergeData = (hash) => {
    respondentsDataStore.current = { ...respondentsDataStore.current, ...hash };
  };
  const mergeDataFlow = (flowIndex, hash) => {
    const newHash = {};
    each(
      hash,
      (val, field) => {
        newHash[fieldNameByFlowIndex(flowIndex, field)] = val;
      }
    );
    mergeData(newHash);
  };

  const valueByFlowIndex = (flowIndex, field) => (
    respondentsDataStore.current[fieldNameByFlowIndex(flowIndex, field)]
  );

  const initStates = () => {
    let flowListInit;
    if (customFlow) {
      flowListInit = [ parseInt(customFlow.match(/\d+$/)[0]) ];
    } else if (customOrder) {
      flowListInit = customOrder.replace(/\s+/g, '').split(',');
    } else {
      flowListInit = shuffle([ 1, 2, 3 ]);
    }
    setFlowList(flowListInit);
    const conceptNameInit = `FLOW${flowListInit[flowIndex]}`;
    setConceptName(conceptNameInit);
  };

  useEffect(() => {
    initStates();
  }, []);

  const nextFlow = (afterIntro) => {
    const flowIndexNew = flowIndex + 1;
    if (flowIndexNew < flowList.length) {
      if (afterIntro) {
        setFlowIndex(flowIndexNew);
        const conceptNameNew = `FLOW${flowList[flowIndexNew]}`;
        setConceptName(conceptNameNew);
      } else {
        setConceptName('MiddleIntro');
      }
    } else {
      setConceptName("Finish");
    }
  };

  const handleComplete = () => {
    sendData(respondentsDataStore.current, fakeData.allPaymentLists);
    setConceptName('Blank');
  };

  let definedComponent = (<></>);
  switch (conceptName) {
    case 'FLOW1':
      definedComponent = (<Flow1App />);
      break;
    case 'FLOW2':
      definedComponent = (<Flow2App />);
      break;
    case 'FLOW3':
      definedComponent = (<Flow3App />);
      break;
    case 'MiddleIntro':
      definedComponent = (
        <MiddleIntro
          nextIndex={ flowIndex + 1 }
          total={ flowList.length }
          next={ nextFlow }
        />
      );
      break;
    case 'Finish':
      definedComponent = (<Finish onReset={ handleComplete } />);
      break;
    default:
      return (<></>);
  }

  return (
    <BaseFlowWrapper
      initialStep="products"
      flowIndex={ flowIndex }
      currentFlow={ flowList[flowIndex] }
      countryCode={ countryCode }
      cell={ cell }
      onNextFlow={ nextFlow }
      respondentsDataStore={ respondentsDataStore }
      mergeData={ mergeData }
      mergeDataFlow={ mergeDataFlow }
      valueByFlowIndex={ valueByFlowIndex }
    >
      { definedComponent }
    </BaseFlowWrapper>
  );
};

export default PaypalCheckoutApp;
