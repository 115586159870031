import React, { useContext, useState } from 'react';
import { map } from "lodash";
import classnames from "classnames";
import Layout from "../../common/components/layout";
import Total from "../../common/components/total";
import CartList from "../../common/components/cartList";
import { GlobalContext } from "../../common/context";
import BaseCartWrapper from '../../common/components/baseCartWrapper';
import { initTime, totalTime, totalTimeMs } from '../../common/statistics';
import QuickPayButton from "./quickPayButton";
import { paymentButtonsList } from './paymentsOptionsHelper';

const Cart = () => {
  const {
    cart, setConfirmedModalShow, translate, countryCode,
    mergeDataFlow, flowIndex, valueByFlowIndex
  } = useContext(GlobalContext);
  const [ loading, setLoading ] = useState(false);
  const [ quickPayList ] = useState(paymentButtonsList(countryCode));

  const choosePayment = (payment) => {
    setLoading(true);
    setTimeout(() => {
      setConfirmedModalShow(true);
      setLoading(false);
    }, 1000);
    mergeDataFlow(
      flowIndex,
      {
        PaymentButtonTimeStamp: initTime(),
        SelectedPaymentId: payment.statisticsId,
        CheckoutTotalTime: totalTime(valueByFlowIndex(flowIndex, 'CheckoutStartTime')),
        CheckoutTotalTimeMs: totalTimeMs(valueByFlowIndex(flowIndex, 'CheckoutStartTime'))
      }
    );
  };

  return (
    <BaseCartWrapper lastStep>
      <Layout loading={ loading }>
        <div className="layout_grid">
          <div className="layout_grid-item -main">
            <div className="cart">
              <h3 className="cart_title -show-mobile">
                { translate("cartSummaryMyItems", countryCode) }
              </h3>
              <CartList />
            </div>
          </div>
          <div className="layout_grid-item -sidebar">
            <div className="plate">
              <h3 className="plate_title">{ translate("orderSummaryTitle", countryCode) }</h3>
              <Total />
              <div className="plate_button">
                <button className="button -red -sm -full-width" disabled>
                  { translate("cartSummaryShippingProceedToCheckout", countryCode) }
                </button>
              </div>
              <div className="quickpay">
                <h4 className="quickpay_title">{ translate("quickPayTitle", countryCode) }</h4>
                <div className="quickpay_buttons">
                  {
                    map(quickPayList, (payment, index) => (
                      <li
                        key={ `${payment.id}-${index}` } className={ classnames(
                          "quickpay_buttons-item",
                          payment.width ? `-${payment.width}` : ''
                        ) }
                      >
                        <QuickPayButton
                          payment={ payment }
                          choosePayment={ choosePayment }
                          disabled={ !cart.length }
                        />
                      </li>
                    ))
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </BaseCartWrapper>
  );
};

export default Cart;


