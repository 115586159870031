import React, { useContext } from "react";
import classnames from "classnames";
import { GlobalContext } from "../../common/context";

const QuickPayButton = ({ payment, choosePayment, disabled }) => {
  const { cell } = useContext(GlobalContext);
  const icon = payment[`icon-cell${cell}`] || payment.icon;
  return (
    <button
      disabled={ disabled }
      className={
        classnames(
          "button -white -full-width quickpay-button",
          payment.id,
          `cell-${cell}`
        )
      }
      onClick={ () => choosePayment(payment) }
    >
      {
        !!icon &&
        <svg className={ classnames("payment-icon", payment.id) }>
          <use xlinkHref={ `#svg_${icon}` } />
        </svg>
      }
      {
        !icon && !!payment.title &&
        <span className="quickpay-button_title">{ payment.title }</span>
      }
    </button>
  );
};

export default QuickPayButton;
