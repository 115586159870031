import React from 'react';
import { map } from 'lodash';
import SliderRadioButtons
  from '../../../express/researcher/verizon_customer_case_v2024/components/sliderRadioButtons';

const BsgAndTabs = ({ tabsList, bsg, selectedBsg, onChangeBsg, selectedTab, onSelectTab }) => {
  const handleSelectTab = (event) => {
    onSelectTab(event.target.value);
  };
  return (
    <div className="comparisons_filters">
      <div className="comparisons_grid -wrap">
        {
          !!bsg &&
          <div className="comparisons_grid-item -fix">
            <SliderRadioButtons
              initValue={ selectedBsg }
              options={
                [
                  { value: bsg.value, title: bsg.label },
                  { value: 'total', title: 'Total' }
                ]
              }
              onChange={ (v) => { onChangeBsg(v); } }
              modificator="-full-width -white-bg -bordered bsg-selector"
            />
          </div>
        }
        <div className="comparisons_grid-item -grow">
          <div className="radio-tabs -gray-bg -bordered -full-width">
            {
              map(tabsList, (tabOption) => (
                <label className="radio-tabs_item" key={ `tab-${tabOption.value}` }>
                  <input
                    id={ `project-${tabOption.value}` }
                    type="radio"
                    checked={ selectedTab === tabOption.value }
                    onChange={ handleSelectTab }
                    value={ tabOption.value }
                  />
                  <div className="radio-tabs_label -comparison">{tabOption.title}</div>
                </label>
              ))
            }
          </div>
        </div>
      </div>
    </div>
  );
};

export default BsgAndTabs;
