import Http from '../../common/http';
import { generateParametersAsString } from './helpers';

const sendRequest = (path, { columns, selectedBsg }, goodCallback, badCallback) => {
  const params = generateParametersAsString({ columns, selectedBsg });
  Http.get(path, params, { parametersAsString: true }).then(
    (response) => {
      if (response.response.ok) {
        goodCallback(response);
      } else {
        badCallback && badCallback(response);
      }
    },
    (reject) => {
      console.log('something went wrong', reject);
      badCallback && badCallback(reject);
    }
  );
};

export { sendRequest };
