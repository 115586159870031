import React from 'react';
import { map, includes } from 'lodash';
import classnames from 'classnames';

const DisplayValue = ({ valueHash, format, noData }) => {
  const letters = valueHash.letters;
  return (
    <div className={ classnames('', { 'note': noData, 'bold': !!letters }) } >
      {
        (noData) ?
          (<div className="note">N/A</div>) :
          (
            <>
              {
                format === 'percent' ?
                  (<>{valueHash.percent_text}{ letters }</>) :
                  (
                    <>
                      {
                        format === 'plus' ?
                          (<>{ plus(valueHash.percent_value) }{ letters }</>) :
                          (<>{ valueHash.percent_value }{ letters }</>)
                      }
                    </>
                  )
              }
            </>
          )
      }
    </div>
  );
};

const TrData = ({ className, text, hint, data, metricFunc, format = 'percent' }) => {
  return (
    <tr>
      <th className={ classnames("th col-name -sticky-left -no-zindex", className) }>
        <div className="bold">{text}</div>
        { !!hint && <div className="note">{hint}</div> }
      </th>
      {
        map(data, (dataItem, index) => (
          <td
            key={ `dataItem-${dataItem.case_id}-${index}` }
            className={ classnames("col-data -center", className) }
          >
            {
              !dataItem.custom &&
              <DisplayValue
                noData={ dataItem.no_data }
                valueHash={ metricFunc(dataItem) }
                format={ format }
              />
            }
          </td>
        ))
      }
    </tr>
  );
};

const plus = (v) => {
  if (v === "0" || v === "N/A" || includes(v, "-")) {
    return v;
  }
  return `+${v}`;
};

export { DisplayValue, TrData };
