import React, { useContext, useEffect, useState } from 'react';
import { includes, keys, pickBy, values } from 'lodash';
import Header from "../common/components/header/index";
import { IndexData } from "../common/index-context";
import { baseContext } from '../../respondent/common/contexts';
import { userDownloadCCPlatform } from '../../../verizonCustomerCaseV2024/common/helpers';
import Comparisons from "./components/comparisons";
import { OpenEndContext } from './contexts';

const AppInner = () => {
  const { init, loadData, data, setSelectedOptions, setSelectedBsg, loading } = useContext(IndexData);
  const { isAdmin, verizon_roles } = init;
  const verizonViewer = includes(verizon_roles, 'verizon_viewer');
  const [ challengesSummary, setChallengesSummary ] = useState(null);
  const [ challengesSummaryTag, setChallengesSummaryTag ] = useState(null);
  const [ model, setModel ] = useState({
    scorecard: true,
    diagnostics: true,
    unhappy: true,
    case_description: true,
    methodology: true,
    effort_high: true,
    effort_low: false,
    appeal_high: false,
    appeal_low: false
  });

  useEffect(() => {
    loadData(null, null, null, null, { fromUrl: true }, (data) => {
      setChallengesSummary(data?.challenges?.summary);
      setChallengesSummaryTag(data?.challenges?.summary_tag);
      setSelectedOptions(data?.selected_sub_groups || []);
      setSelectedBsg(data?.selected_bsg);
      setModel({ ...model, effort_high: !!data.high_effort_summaries.length });
    });
  }, []);

  const reports = [];

  reports.push({
    title: 'Scorecard',
    name: 'cit_scorecard',
    kinds: [ 'google', 'google_pdf' ],
    kindTitles: [ 'Google Slides', 'PDF' ]
  });
  if (!verizonViewer) {
    reports.push({
      title: 'Respondent Level',
      name: 'verizon_customer_case_v2024_all_respondents',
      kinds: isAdmin ? [ 'xlsx', 'google' ] : [ 'google' ],
      kindTitles: isAdmin ? [ 'XLSX', 'Google Sheets' ] : [ 'Google Sheets' ]
    });
  }

  reports.push({
    title: 'Open Ends',
    name: 'verizon_customer_case_v2024_open_ends',
    kinds: [ 'google' ],
    kindTitles: [ 'Google Sheets' ]
  });

  if (isAdmin) {
    reports.push({ title: 'Quota Details', name: 'quotas_details', kinds: [ 'xlsx' ] });
  }

  const handleUpdateSummary = (aiSummary) => {
    const rawDataHashesList = values(aiSummary.raw_data_hashes || {});
    if (aiSummary.tag === challengesSummaryTag) {
      const challenges = aiSummary.challenges;
      setChallengesSummary(challenges);
      data.challenges.summary = challenges;
      data.high_effort_summaries = rawDataHashesList;
    }
    if (aiSummary.tag === 'vcc_effort_open_end__low') {
      data.low_effort_summaries = rawDataHashesList;
    }
    if (aiSummary.tag === 'vcc_appeal_open_end__low') {
      data.low_appeal_summaries = rawDataHashesList;
    }
    if (aiSummary.tag === 'vcc_appeal_open_end__high') {
      data.high_appeal_summaries = rawDataHashesList;
    }
  };

  const openEndContextValue = {
    challengesSummary, handleUpdateSummary, isAdmin, setChallengesSummary,
    aiSummaryAvailable: init.ai_summary_available
  };
  const baseContextValue = {
    translations: data?.respondent_translations,
    model,
    setModel
  };

  const handleGeneratingExportParams = (reportItem, reportKindIndex, reportTitle, formatTitle) => {
    return {
      platform: userDownloadCCPlatform,
      report_title: `${init.case_id} ${init.case_name} %date%: ${reportTitle}`,
      format_title: formatTitle,
      slides: keys(pickBy(model, (value, key) => (value === true)))
    };
  };

  return (
    <baseContext.Provider value={ baseContextValue }>
      <OpenEndContext.Provider value={ openEndContextValue }>
        <div className="content -loading-wrapper tour-parent-header -research verizon-modifier">
          <Header
            customTitle={ init.case_name }
            reports={ reports }
            generateExportParams={ handleGeneratingExportParams }
            plural="concepts"
            sortGroups={ [] }
            showCustomizeView={ false }
            customHeaderButton={
              <a className="button header_button button" href={ init.comparison_side_by_side_url }>
                Compare Subgroups
              </a>
            }
          />

          {loading && <div className="page-loader -inner-loader -transparent -fixed" />}

          <Comparisons />
        </div>
      </OpenEndContext.Provider>
    </baseContext.Provider>
  );
};

export default AppInner;
