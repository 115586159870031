import React, { useContext, useState } from 'react';
import { map } from "lodash";
import Layout from "../../common/components/layout";
import Total from "../../common/components/total";
import Shipping from "../../common/components/shipping";
import { GlobalContext } from "../../common/context";
import { shippingErrorModalData } from '../../common/helper';
import PaymentButton from '../../common/components/paymentButton';
import BasePaymentWrapper from '../../common/components/basePaymentWrapper';
import { initTime, totalTime, totalTimeMs } from '../../common/statistics';
import { paymentButtonsList } from './paymentsOptionsHelper';

const Payment = () => {
  const {
    setConfirmedModalShow, shippingValid, setCustomModalObj, translate, countryCode,
    flowIndex, mergeDataFlow, valueByFlowIndex
  } = useContext(GlobalContext);
  const [ loading, setLoading ] = useState(false);
  const [ paymentList ] = useState(() => paymentButtonsList(countryCode));

  const choosePayment = (payment) => {
    if (shippingValid) {
      setLoading(true);
      setTimeout(() => {
        setConfirmedModalShow(true);
        setLoading(false);
      }, 1000);
      mergeDataFlow(
        flowIndex,
        {
          PaymentButtonTimeStamp: initTime(),
          SelectedPaymentId: payment.statisticsId,
          ShippingTotalTime: totalTime(valueByFlowIndex(flowIndex, 'ShippingStartTime')),
          ShippingTotalTimeMs: totalTimeMs(valueByFlowIndex(flowIndex, 'ShippingStartTime'))
        }
      );
    } else {
      setCustomModalObj(shippingErrorModalData(translate, countryCode));
    }
  };

  return (
    <BasePaymentWrapper>
      <Layout headerFull={ false } loading={ loading }>
        <div className="layout_grid">
          <div className="layout_grid-item -main">
            <div className="payment">
              <Shipping />
              <div className="payment_section">
                <h3 className="payment_section-title">{ translate("paymentTitle", countryCode) }</h3>
                <ul className="payment_list -buttons">
                  {
                    map(paymentList, (payment, index) => (
                      <li key={ `${payment.id}-${index}` } className="payment_list-item">
                        <PaymentButton payment={ payment } onClick={ choosePayment } />
                      </li>
                    ))
                  }
                </ul>
              </div>
            </div>
          </div>
          <div className="layout_grid-item -sidebar">
            <div className="plate">
              <h3 className="plate_title">{ translate("checkoutTitle", countryCode) }</h3>
              <Total />
            </div>
          </div>
        </div>
      </Layout>
    </BasePaymentWrapper>
  );
};

export default Payment;


