import React, { useContext, Fragment } from 'react';
import { map, find } from 'lodash';
import ImageInPopup from '../../../../common/components/image_in_popup';
import ErrorMessage from '../../../admin/projects/components/common/errorMessage';
import { GlobalContext } from '../../contexts';
import { DisplayValue } from '../trData';

const CommonTableHeader = ({ ccScore }) => {
  const { dataAsArray, mainData, noTotalColumn } = useContext(GlobalContext);

  const lowNSize = find(dataAsArray, (hash) => (hash?.messages?.low_n_size));
  const lowNSizeMessage = lowNSize ? lowNSize.messages.low_n_size : null;

  return (
    <>
      <tr className="tr-head -sticky">
        <th className="th col-name -sticky-left -center">
          <div className="comparisons-table_header-image">
            <ImageInPopup
              customZIndex={ 1500 }
              src={ mainData.concept_image_url }
              hoverClassName="verizon-modifier comparison-image-popup"
            >
              <img width="600" src={ mainData.concept_image_url } alt={ mainData.case_name } />
            </ImageInPopup>
          </div>
        </th>
        {
          map(dataAsArray, (dataItem, index) => (
            <Fragment key={ `name-${dataItem.case_id}-${index}` }>
              { dataItem.headerComponent }
            </Fragment>
          ))
        }
      </tr>
      {
        (!noTotalColumn || dataAsArray.length > 1) &&
        <tr className="tr-head -sticky -sticky-alphabet-margin">
          <th className="th col-name -sticky-left -no-zindex" />
          {
            map(dataAsArray, (dataItem, index) => (
              <td key={ `alphabet-${dataItem.case_id}-${index}` } className="col-data -center">
                {
                  !dataItem.custom &&
                  <b>{dataItem.alphabet_letter}</b>
                }
              </td>
            ))
          }
        </tr>
      }

      <tr>
        <th className="th col-name -sticky-left -no-zindex">
          <div className="bold">Sample Size</div>
          <ErrorMessage message={ lowNSizeMessage?.text } />
        </th>
        {
          map(dataAsArray, (dataItem, index) => (
            <td key={ `size-${dataItem.case_id}-${index}` } className="col-data -center">
              {
                !dataItem.custom &&
                <>
                  ({dataItem.n})
                  {dataItem.messages.low_n_size && <span className="red">*</span>}
                </>
              }
            </td>
          ))
        }
      </tr>
      {
        ccScore &&
        <tr>
          <th className="th col-name -sticky-left -no-zindex">
            <div className="bold">Customer Case Score</div>
          </th>
          {
            map(dataAsArray, (dataItem, index) => (
              <td key={ `cc-score-${dataItem.case_id}-${index}` } className="col-data -center">
                {
                  !dataItem.custom &&
                  <DisplayValue valueHash={ dataItem.ease_meter } format="number" />
                }
              </td>
            ))
          }
        </tr>
      }
    </>
  );
};

export default CommonTableHeader;
